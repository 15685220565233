import { default as codice_45otpEmcDYrwCElMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/codice-otp.vue?macro=true";
import { default as codiceQaxCBO3v58Meta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/codice.vue?macro=true";
import { default as indexDdLjWllFZZMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/admin/index.vue?macro=true";
import { default as indexXNePvbHfReMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/clienti/index.vue?macro=true";
import { default as creazione_disegniW2ahL0Az5iMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/creazione_disegni.vue?macro=true";
import { default as descrizionizlX9ooiHOZMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/descrizioni.vue?macro=true";
import { default as index5iUr5ZqNScMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/index.vue?macro=true";
import { default as numerazione_aHN9em49CrbMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_a.vue?macro=true";
import { default as numerazione_bq940iANZzjMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_b.vue?macro=true";
import { default as indexSReihVQEvZMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/fornitori/index.vue?macro=true";
import { default as indexeKIk6cQf3RMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/generatore/index.vue?macro=true";
import { default as indexZQI41Zy7o4Meta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/index.vue?macro=true";
import { default as indexE9c3JVs5SWMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/log/index.vue?macro=true";
import { default as createBb69ffTqfJMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/create.vue?macro=true";
import { default as indexDQHEqx6qz5Meta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/index.vue?macro=true";
import { default as indexY20ZFfaDz2Meta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/profilo/index.vue?macro=true";
import { default as indexQBN41BGC2WMeta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/index.vue?macro=true";
import { default as recupera_45passwordVWUC4URY16Meta } from "C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/recupera-password.vue?macro=true";
export default [
  {
    name: "codice-otp",
    path: "/codice-otp",
    meta: codice_45otpEmcDYrwCElMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/codice-otp.vue")
  },
  {
    name: "codice",
    path: "/codice",
    meta: codiceQaxCBO3v58Meta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/codice.vue")
  },
  {
    name: "dashboard-admin",
    path: "/dashboard/admin",
    meta: indexDdLjWllFZZMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/admin/index.vue")
  },
  {
    name: "dashboard-clienti",
    path: "/dashboard/clienti",
    meta: indexXNePvbHfReMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/clienti/index.vue")
  },
  {
    name: "dashboard-disnum-creazione_disegni",
    path: "/dashboard/disnum/creazione_disegni",
    meta: creazione_disegniW2ahL0Az5iMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/creazione_disegni.vue")
  },
  {
    name: "dashboard-disnum-descrizioni",
    path: "/dashboard/disnum/descrizioni",
    meta: descrizionizlX9ooiHOZMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/descrizioni.vue")
  },
  {
    name: "dashboard-disnum",
    path: "/dashboard/disnum",
    meta: index5iUr5ZqNScMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/index.vue")
  },
  {
    name: "dashboard-disnum-numerazione_a",
    path: "/dashboard/disnum/numerazione_a",
    meta: numerazione_aHN9em49CrbMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_a.vue")
  },
  {
    name: "dashboard-disnum-numerazione_b",
    path: "/dashboard/disnum/numerazione_b",
    meta: numerazione_bq940iANZzjMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/disnum/numerazione_b.vue")
  },
  {
    name: "dashboard-fornitori",
    path: "/dashboard/fornitori",
    meta: indexSReihVQEvZMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/fornitori/index.vue")
  },
  {
    name: "dashboard-generatore",
    path: "/dashboard/generatore",
    meta: indexeKIk6cQf3RMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/generatore/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexZQI41Zy7o4Meta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-log",
    path: "/dashboard/log",
    meta: indexE9c3JVs5SWMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/log/index.vue")
  },
  {
    name: "dashboard-manualis-create",
    path: "/dashboard/manualis/create",
    meta: createBb69ffTqfJMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/create.vue")
  },
  {
    name: "dashboard-manualis",
    path: "/dashboard/manualis",
    meta: indexDQHEqx6qz5Meta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/manualis/index.vue")
  },
  {
    name: "dashboard-profilo",
    path: "/dashboard/profilo",
    meta: indexY20ZFfaDz2Meta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/dashboard/profilo/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQBN41BGC2WMeta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/index.vue")
  },
  {
    name: "recupera-password",
    path: "/recupera-password",
    meta: recupera_45passwordVWUC4URY16Meta || {},
    component: () => import("C:/PROGETTI PERSONALIZZATI/GESTIONALI/bt-studio-generatore-documenti/pages/recupera-password.vue")
  }
]